/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('./files/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('./files/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./files/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./files/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./files/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./files/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('./files/roboto-v30-latin-italic.eot'); /* IE9 Compat Modes */
  src: url('./files/roboto-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./files/roboto-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('./files/roboto-v30-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('./files/roboto-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./files/roboto-v30-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('./files/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: url('./files/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./files/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./files/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./files/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./files/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-regular - latin
@font-face {
  font-display: swap;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('./files/roboto-condensed-v25-latin-regular.eot');
  src: url('./files/roboto-condensed-v25-latin-regular.eot?#iefix') format('embedded-opentype'), 
       url('./files/roboto-condensed-v25-latin-regular.woff2') format('woff2'),
       url('./files/roboto-condensed-v25-latin-regular.woff') format('woff'), 
       url('./files/roboto-condensed-v25-latin-regular.ttf') format('truetype'),
       url('./files/roboto-condensed-v25-latin-regular.svg#RobotoCondensed') format('svg'); 
}
*/
/* roboto-condensed-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('./files/roboto-condensed-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: url('./files/roboto-condensed-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./files/roboto-condensed-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./files/roboto-condensed-v25-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./files/roboto-condensed-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./files/roboto-condensed-v25-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
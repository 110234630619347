

$primary: #00014f;
$secondary: #457B9D;
$success: #e63946;
$info: #a8dadc;
$danger: red;
$light: #f1faee;
$dark: #000000;

$h1-font-size: 2rem;
$h2-font-size: 1.75rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.25rem;

@import "~bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap/scss/variables";
@import url('./fonts/fonts.scss');

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  color: #121212;
}

h1, h2, h3, h4, h5 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

main {
  display: flex;
  flex: 1;
}

a.link-inverse {
  border-radius: 4px;
  background: #fff;
  color: var(--bs-link-color);
  padding: 2px 4px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.map-view {
  border: 2px #2e425a solid;
  height: 300px;
  margin-bottom: 1.5em;
}

.photo-gallery, .audio-gallery {
  margin-top: 1.5rem;
}



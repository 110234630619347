
.photo-link {
  text-decoration: none;
}
.photo-link-wrap {

  overflow: hidden;
  img {
    max-width: 100%;
    transition: all 0.3s ease;

  }
}

.photo-link:hover, .photo-link:focus {
  text-decoration: underline;
  .photo-link-wrap {
  
    img {
      transform: scale(1.1);
    }
  }
}